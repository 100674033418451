import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/main/MainIndex.vue'),
    children: [
      {
        path: '/addemployee',
        name: 'addemployee',
        component: () => import('../views/system/AddEmployeeView.vue')
      }, {
        path: '/system/changepassword',

        component: () => import('../views/system/ChangePassword.vue')
      },
      {
        path: '/employee',
        name: 'employee',
        component: () => import('../views/system/EmployeeView.vue')
      },
      {
        path: '/customerList',
        name: 'CustomerList',
        component: () => import('../views/main/CustomerList.vue')
      },
      {
        path: '/stockout',
        name: 'stockout',
        component: () => import('../views/stock/StockOut.vue')
      },
      {
        path: '/stockchange',
        name: 'stockchange',
        component: () => import('../views/stock/StockChange.vue')
      },
      {
        path: '/stockin',
        name: 'stockin',
        component: () => import('../views/stock/StockIn.vue')
      }, {
        path: '/addorder',
        name: 'addorder',

        component: () => import('@/views/sale/AddOrder.vue')
      }, {
        path: '/orderlist',
        name: 'orderlist',

        component: () => import('@/views/sale/OrderList.vue')
      },
      {
        path: '/sale/orderlist',
        name: 'sale_orderlist',

        component: () => import('@/views/sale/OrderList.vue')
      },
       {
        path: '/addleave',
        name: 'addleave',

        component: () => import('@/views/sale/AddLeave.vue')
      }, {
        path: '/leaveapproval',
        name: 'leaveapproval',
        component: () => import('@/views/sale/LeaveApproval.vue')
      },
      {
        path: '/addback',
        name: 'addback',
        component: () => import('@/views/sale/AddBack.vue')
      },
      {
        path: '/stockback',
        name: 'stockback',

        component: () => import('@/views/stock/StockBack.vue')
      }, {
        path: '/stockdetail',
        name: 'stockdetail',

        component: () => import('@/views/stock/StockDetail.vue')
      }, {
        path: '/stockpile',
        name: 'stockpile',

        component: () => import('@/views/stock/StockPile.vue')
      },
      {
        path: '/stockpiledetail',
        name: 'stockpiledetail',

        component: () => import('@/views/stock/StockPileDetail.vue')
      },
      {
        path: '/addcustomer',
        name: 'addcustomer',

        component: () => import('@/views/sale/AddCustomer.vue')
      },
      {
        path: '/stockprint',
        name: 'stockprint',

        component: () => import('@/views/stock/StockPrint.vue')
      },
      {
        path: '/finance/collection',
        name: 'collection',

        component: () => import('@/views/finance/Collection.vue')
      },
      {
        path: '/finance/collectionlist',
        name: 'collectionlist',

        component: () => import('@/views/finance/CollectionList.vue')
      },
      {
        path: '/finance/invoicelist',
        name: 'invoicelist',

        component: () => import('@/views/finance/InvoiceList.vue')
      },
      {
        path: '/sale/customersum',
        name: 'customersum',

        component: () => import('@/views/sale/CustomerSum.vue')
      },
      {
        path: '/sale/addinvoice',
        name: '/sale_addinvoice',

        component: () => import('@/views/sale/AddInvoice.vue')
      },
      {
        path: '/search/customersum',
        name: 'search_customersum',

        component: () => import('@/views/sale/CustomerSum.vue')
      },
      {
        path: '/finance/invoice',
        name: 'invoice',

        component: () => import('@/views/finance/invoice.vue')
      }, 
      {
        path: '/finance/invoice2',
        name: 'invoice2',

        component: () => import('@/views/finance/invoice2.vue')
      }, 
            {
        path: '/system/addmenu',
        name: 'addmenu',

        component: () => import('@/views/system/AddMenu.vue')
      },  
      {
        path: '/finance/collectiondetaillist',
        name: 'collectiondetaillist',

        component: () => import('@/views/finance/CollectionDetailList.vue')
      },
      {
        path: '/finance/invoicedetaillist',
        name: 'invoicedetaillist',

        component: () => import('@/views/finance/InvoiceDetailList.vue')
      },      
      {
        path: '/sale/addlicense',
        name: 'addlicense',

        component: () => import('@/views/sale/AddLicense.vue')
      },
      {
        path: '/addmenu',
        name: 'addmenu',

        component: () => import('@/views/system/AddMenu.vue')
      },      
          {
        path: '/system/addproduct',
        name: 'addproduct',

        component: () => import('@/views/system/AddProduct.vue')
      }, 
               {
        path: '/system/deptlist',       
        name: 'deptlist',

        component: () => import('@/views/system/DeptList.vue')
      },
      {
        path: '/stock/stocklist',
        name: 'stocklist',

        component: () => import('@/views/stock/StockList.vue')
      },
      {
        path: '/sale/leavedelete',
        name: 'leavedelete',

        component: () => import('@/views/sale/LeaveDelete.vue')
      },
      {
        path: '/uplog',
        name: 'uplog',

        component: () => import('@/views/system/upListView.vue')
      },
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/main/MainIndex.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/TableEdit.vue')
  },
    {
    path: '/test2',
    name: 'test2',
    component: () => import('@/views/test/test.vue')
  },{
    path: '/excel',
    name: 'excel',

    component: () => import('@/views/test/export2.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
